import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PRODUCTS_LIST = "products_list";
export const PRODUCTS_SIMPLE_LIST = "products_simple_list";
export const CREATE_PRODUCT = "create_product";
export const UPDATE_PRODUCT = "update_product";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const DELETE_PRODUCT = "delete_product";
export const GET_PRODUCT_DETAILS = "get_product_details";
export const CLEAR_PRODUCT_ERRORS = "clearProductErrors";

// mutation types
export const SET_PRODUCT_ERROR = "setProductError";
export const REMOVE_PRODUCT_ERRORS = "removeProductErrors";

const state = {
    errors: null
};


const actions = {
    [PRODUCTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/products?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PRODUCT_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [PRODUCTS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/productssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [GET_PRODUCT_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getproductdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PRODUCT_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [CREATE_PRODUCT](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createproduct", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PRODUCT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PRODUCT](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateproduct", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PRODUCT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PRODUCT_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateproductstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PRODUCT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [DELETE_PRODUCT](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteproduct", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PRODUCT_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PRODUCT_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PRODUCT_ERRORS](context) {
        context.commit(REMOVE_PRODUCT_ERRORS);
    },
};

const mutations = {
    [SET_PRODUCT_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PRODUCT_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
